import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import MainTemplate from "templates/Main/MainTemplate"
import styled from "styled-components"
import theme from "assets/styles/theme"

const Wrapper = styled.div`
    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    padding-bottom: 90px;
    max-width: 100%;

    ${theme.mq.small} {
        flex-direction: column;
    }
`

const LeftPart = styled.div`
    min-height: 100%;
    width: 100%;
    flex: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${({ desktopBackground }) =>
    "" + desktopBackground + "" ? desktopBackground : ""});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-box-shadow: inset -120px 0px 40px 10px rgba(0,0,0,1);
    -moz-box-shadow: inset -120px 0px 40px 10px rgba(0,0,0,1);
    box-shadow: inset -120px 0px 40px 10px rgba(0,0,0,1);

    ${theme.mq.small} {
        -webkit-box-shadow: inset 0px -120px 40px 10px rgba(0,0,0,1);
        -moz-box-shadow: inset 0px -120px 40px 10px rgba(0,0,0,1);
        box-shadow: inset 0px -120px 40px 10px rgba(0,0,0,1);
    }
`

const RightPart = styled.div`
    flex: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
`

const PhotoContainer = styled.div`
    position: relative;
    right: -40%;
    height: auto;
    width: 400px;

    ${theme.mq.medium} {
        right: -20%;
        max-width: 300px;
    }

    ${theme.mq.small} {
        right: 0;
        left: 0;
    }
`

const Content = styled.div`
    color: white;
    width: 60%;
    padding: 0px 15px;

    ${theme.mq.small} {
        width: 80%;
        text-align: center;
    }
`

const MainTitle = styled.h3`
    font-size: 4rem;
    font-weight: initial;
    margin: 2rem 0;

    ${theme.mq.small} {
        font-size: 3rem;
    }
}
`

const MainDescription = styled.h5`
    font-size: 1.8rem;
    font-weight: initial;
    line-height: 2;
    
    a, a:active, a:visited {
        text-decoration: underline;
        color: white;
    }
`

const HintLink = styled.div`
    margin: 2rem 0;
    font-size: 1.4rem;
    width: 100%;
    font-weight: bold;
    position: relative;
    
    a, a:active, a:visited {
        text-decoration: underline;
        color: white;
    }
`

const DesktopPage = ({ data }) => (
    <MainTemplate>
        <Wrapper>
            <LeftPart desktopBackground={data.desktopBackground.childImageSharp.fluid.src}>
                <PhotoContainer>
                    <Img fluid={data.mobileImage.childImageSharp.fluid}/>
                </PhotoContainer>
            </LeftPart>

            <RightPart>
                <Content>
                    <Img fixed={data.logo.childImageSharp.fixed}/>
                    <MainTitle>
                        Pobierz aplikację BAR IS US
                    </MainTitle>
                    <MainDescription>
                        Aby pobrać aplikację, wejdź na podstronę, na której się obecnie znajdujesz:
                        <a href={process.env.SITE_URL}> app.barisus.pl</a> ze swojego smartfona i dodaj ją do ekranu
                        startowego. Dzięki temu aplikacja zostanie zainstalowana, a Ty będziesz miał do niej szybki i
                        łatwy dostęp. Zdobywaj unikalne nagrody! Powodzenia!
                    </MainDescription>
                    <HintLink>
                        <a href="https://barisus.pl/faq">Dowiedz się jak pobrać aplikację</a>
                    </HintLink>
                    <HintLink>
                        <a href="https://barisus.pl/">Wróć do strony głównej</a>
                    </HintLink>
                </Content>
            </RightPart>

        </Wrapper>
    </MainTemplate>
)

export default DesktopPage

export const query = graphql`
    {
        desktopBackground: file(relativePath: { eq: "desktop/background.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        mobileImage: file(relativePath: { eq: "desktop/mobile.png" }) {
            childImageSharp {
                fluid (maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        logo: file(relativePath: { eq: "logo_glass.png" }) {
            childImageSharp {
                fixed (width: 100) {
                    ...GatsbyImageSharpFixed_tracedSVG
                }
            }
        }
    }
`